import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { HouseholdService } from '../../services/household.service';
import { JourneyLogsService } from '../../services/journey-logs.service';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';

@Component({
  selector: 'header',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  constructor(
    public dialog: MatDialog,
    public householdService: HouseholdService,
    private router: Router,
    private authService: AuthService,
    private journeyLogsService: JourneyLogsService
  ) {
  }

  @Input() showSaveAndExitButton = true;
  @Input() showLogoutButton = false;
  
  onSaveAndExitButtonClicked(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: "Are you sure you want to exit?",
        message: "Your changes will be saved.",
        mainButtonText: "Save and exit",
        altButtonText: "Cancel",
        showAltButton: true,
        showIcon: true
      },
    });

    const handler = async () => {            
      await this.router.navigate(["/save-confirmation"]);
    };
    
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {        
        void handler();
      }
    });
  }

  async onLogoutButtonClicked(): Promise<void> {
    await this.journeyLogsService.createLogOutLog();
    await this.authService.logout();
  }
}
