import { ChangeDetectionStrategy, Component, Input, OnInit, signal } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { Router } from '@angular/router';
import { Step } from '../../../entities/step';
import { StepState } from '../../../enums/step-state';

@Component({
  selector: 'step',
  standalone: true,
  imports: [],
  templateUrl: './step.component.html',
  styleUrl: './step.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepComponent implements OnInit {
  constructor(
    private navigationService: NavigationService,
    private router: Router) {
  }

  @Input() step!: Step;
  buttonClass = signal("disabled");
  leftLineCompleted = signal(false);
  rightLineCompleted = signal(false);
  disableNavigation = signal(true);

  ngOnInit(): void {
    this.navigationService.currentStep.subscribe((step) => {
      if (this.step.identifier === step.identifier) this.step = step;

      this.leftLineCompleted.set(this.step.leftCompleted);
      this.rightLineCompleted.set(this.step.rightCompleted);

      this.buttonClass.set(this.getButtonClass(this.step));
    });
  }

  async onNavigateClick(): Promise<boolean> {
    const navigationPath = this.step.overriddenStepNavigationIdentifier ?? this.step.identifier;

    return await this.router.navigate([`/${navigationPath}`]);
  }

  private getButtonClass(step: Step): string {
    if (step.identifier !== this.step.identifier) {
      this.disableNavigation.set(true);
      return "disabled";
    }

    switch (step.stepState) {
      case StepState.Current:
        this.disableNavigation.set(false);
        return "active";
      case StepState.Completed:
        this.disableNavigation.set(false);
        return "completed";
      case StepState.Visited:
        this.disableNavigation.set(false);
        return "visited";
      default:
        this.disableNavigation.set(true);
        return "disabled";
    }
  }
}
