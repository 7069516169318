import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withXsrfConfiguration } from '@angular/common/http';
import { BlockUIModule } from 'ng-block-ui';
import { CommonModule } from '@angular/common';
import { CustomErrorHandlerService } from './services/custom-error-handler.service';
import { CustomRequestInterceptorService } from './services/custom-request-interceptor.service';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';

export const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withFetch(), withXsrfConfiguration({ cookieName: "XSRF-TOKEN", headerName: "RequestVerificationToken" })),
    importProvidersFrom(BlockUIModule.forRoot()),
    CommonModule,
    provideMomentDateAdapter(MY_DATE_FORMAT),
    {
      provide: ErrorHandler,
      useExisting: CustomErrorHandlerService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: CustomRequestInterceptorService,
      multi: true
    }
  ]
};
