import { DialogData, ErrorDialogComponent } from '../shared/error-dialog/error-dialog.component';
import { EMPTY, Subject, Subscription, auditTime, buffer, catchError, map, mergeMap } from 'rxjs';
import { ErrorHandler, Injectable, NgZone, OnDestroy } from '@angular/core';
import { createGuid, uniqueBy } from '../shared/util';
import { ComponentInitialisationError } from '../custom-errors/component-initialisation-error';
import { HttpErrorResponse } from '@angular/common/http';
import { JourneyLog } from '../entities/journey-log';
import { JourneyLogType } from '../enums/journey-log-type';
import { JourneyLogsService } from './journey-logs.service';
import { JourneyService } from './journey.service';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandlerService implements ErrorHandler, OnDestroy {
  constructor(
    private readonly dialog: MatDialog,
    private readonly journeyLogsService: JourneyLogsService,
    private readonly journeyService: JourneyService,
    private readonly navigationService: NavigationService,
    private zone: NgZone) {
    this.setupErrorlogPipeline();
  }

  private readonly stacktraceNotAvailableMessage = "<stack trace not available>";
  private readonly errorDoesNotContainMessageProperty = "Error message could not be determined because the error object does not contain a string message property.";
  private readonly errorLogs = new Subject<JourneyLog>();
  private readonly BUFFER_INTERVAL_MS = 3000;
  private readonly MAX_ERRORS_TO_LOG_PER_INTERVAL = 10;
  private readonly zero = 0;
  private subscriptions: Subscription[] = [];

  private setupErrorlogPipeline() {
    this.subscriptions.push(this.errorLogs.pipe(
      // We use buffer instead of bufferTimer because bufferTimer emits even if there is nothing in the buffer.
      // With buffer, we only emit if there are data in the buffer.
      //https://github.com/ReactiveX/rxjs/issues/2601
      buffer(this.errorLogs.pipe(auditTime(this.BUFFER_INTERVAL_MS))),
      map(logs => uniqueBy(logs, log => log.message)),
      mergeMap(logs => {
        const logsToLog = logs.slice(this.zero, this.MAX_ERRORS_TO_LOG_PER_INTERVAL);
        if (logs.length > this.MAX_ERRORS_TO_LOG_PER_INTERVAL) {
          logsToLog.push(this.getLogLimitExceededLog(logs.length));
        }
        return this.journeyLogsService.saveJourneyLog(logs).pipe(catchError(err => {
          // eslint-disable-next-line no-console
          console.error(err);
          return [];
        }));
      })
    ).subscribe());
  }

  private getLogLimitExceededLog(errorCount: number): JourneyLog {
    const truncatedErrorsNumber = errorCount - this.MAX_ERRORS_TO_LOG_PER_INTERVAL;
    return this.journeyLogsService.createJourneyLog(
      JourneyLogType.Error,
      (this.journeyService.journey ? this.journeyService.journey.journeyID : createGuid()),
      `${errorCount} errors have occurred. This is too many to log. ${truncatedErrorsNumber} were not logged.`)
  }

  handleError(error: unknown): void {
    // eslint-disable-next-line no-console
    console.error(error);

    const stacktrace = this.getStacktrace(error);
    const errorMessage = this.getErrorMessage(error);
    const navigateToPreviousPage = error instanceof ComponentInitialisationError && error.navigateToSafePage;

    const logMessage = `Error:${errorMessage}; Stacktrace: ${stacktrace}`;

    const journeyLog = this.journeyLogsService.createJourneyLog(
      error instanceof HttpErrorResponse
        ? JourneyLogType.Error
        : JourneyLogType.ClientSideError,
      (this.journeyService.journey ? this.journeyService.journey.journeyID : createGuid()),
      logMessage
    );

    this.errorLogs.next(journeyLog);

    this.zone.run(() => this.dialog.open<ErrorDialogComponent, DialogData>(ErrorDialogComponent, {
      disableClose: true,
      data: {
        errorMessage,
        eventId: journeyLog.journeyLogID,
        stacktrace
      }
    }).afterClosed()
      .pipe(map(() => {
        if (navigateToPreviousPage) {
          return this.navigationService.navigateBack()
        }
        return EMPTY;
      })).subscribe()
    )
  }

  private getStacktrace(error: unknown): string {
    if (error instanceof ComponentInitialisationError && error.originalError instanceof Error && error.originalError.stack) {
      return error.originalError.stack;
    }

    if (error instanceof Error && error.stack) {
      return error.stack;
    }

    return this.stacktraceNotAvailableMessage;
  }

  private getErrorMessage(error: unknown): string {
    if (error instanceof Error) {
      return error.message
    }

    if (error && typeof error === 'object' && "message" in error && typeof error.message === 'string') {
      return error.message;
    }

    return `${this.errorDoesNotContainMessageProperty}: ${String(error)}`;
  }

  ngOnDestroy(): void {
    this.errorLogs.unsubscribe();
    this.subscriptions.forEach(x => { x.unsubscribe() });
  }
}
