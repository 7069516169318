import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JourneyLogsService } from '../services/journey-logs.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpClient: HttpClient,
    private journeyLogsService: JourneyLogsService
  ) {}

  async isAuthenticatedAsync(): Promise<boolean> {
    const api = `/api/session/authenticated`;

    const caller = this.httpClient.get<boolean>(api);

    return await lastValueFrom(caller);
  }

  async logout(): Promise<void> {
    await this.journeyLogsService.createLogOutLog();
    window.location.assign(`logout`);
  }
}
