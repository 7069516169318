<div class="headerSection">
  <div class="headerShadow">
    <div class="headerContent">
      <div class="logoContainer">
        <div class="logo">
          <div class="logoImage"></div>
          <div class="logoText">
            <div class="textBlock">Financial</div>
            <div class="textBlock2">Wellbeing</div>
          </div>
        </div>
      </div>
      @if (showSaveAndExitButton) {
      <div class="endContent">
        <button mat-button class="altButton" (click)="onSaveAndExitButtonClicked()">Save and exit <img
            src="assets/exit-icon.svg" alt="" /></button>
      </div>
      }

      @if (showLogoutButton) {
        <div class="endContent">
          <button mat-button class="altButton" (click)="onLogoutButtonClicked()">Logout <img
              src="assets/exit-icon.svg" alt="" /></button>
        </div>
        }
    </div>
  </div>
</div>