import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { Step } from '../../entities/step';
import { StepComponent } from './step/step.component';

@Component({
  selector: 'navigation',
  standalone: true,
  imports: [StepComponent],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent {
  constructor(
    public navigationService: NavigationService
  ) { }

  get steps(): Step[] {
    return this.navigationService.steps.filter(x => !x.parentStepID);
  }
}
