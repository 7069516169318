import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { canDeactivateGuard } from './guards/can-deactivate.guard';

export const routes: Routes = [
    {
        path: '',
        title: 'Welcome',
        loadComponent: () => import("./steps/welcome/welcome.component").then((m) => m.WelcomeComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'welcome',
        title: 'Welcome',
        loadComponent: () => import("./steps/welcome/welcome.component").then((m) => m.WelcomeComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'privacy',
        title: "Why You're Here Today",
        loadComponent: () => import("./steps/welcome/privacy/privacy.component").then((m) => m.PrivacyComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'successful-meeting',
        title: "Why You're Here Today",
        loadComponent: () => import("./steps/welcome/successful-meeting/successful-meeting.component").then((m) => m.SuccessfulMeetingComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'wellbeing-check-in',
        title: "Wellbeing check-in",
        loadComponent: () => import("./steps/wellbeing-check-in/wellbeing-check-in.component").then((m) => m.WellbeingCheckInComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'financial-wellbeing',
        title: "Financial wellbeing",
        loadComponent: () => import("./steps/wellbeing-check-in/financial-wellbeing/financial-wellbeing.component").then((m) => m.FinancialWellbeingComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'physical-wellbeing',
        title: "Physical wellbeing",
        loadComponent: () => import("./steps/wellbeing-check-in/physical-wellbeing/physical-wellbeing.component").then((m) => m.PhysicalWellbeingComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'mental-wellbeing',
        title: "Mental wellbeing",
        loadComponent: () => import("./steps/wellbeing-check-in/mental-wellbeing/mental-wellbeing.component").then((m) => m.MentalWellbeingComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'save-confirmation',
        title: "Save confirmation",
        loadComponent: () => import("./save-confirmation/save-confirmation.component").then((m) => m.SaveConfirmationComponent)
    },
    {
        path: 'about-you',
        title: "About you",
        loadComponent: () => import("./steps/about-you/about-you.component").then((m) => m.AboutYouComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'lifestyle',
        title: "Lifestyle",
        loadComponent: () => import("./steps/about-you/lifestyle/lifestyle.component").then((m) => m.LifestyleComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'wealth',
        title: "Wealth",
        loadComponent: () => import("./steps/about-you/wealth/wealth.component").then((m) => m.WealthComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'future-protection',
        title: "Future Protection",
        loadComponent: () => import("./steps/about-you/future-protection/future-protection.component").then((m) => m.FutureProtectionComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'cashflow',
        title: "Cashflow",
        loadComponent: () => import("./steps/about-you/cashflow/cashflow.component").then((m) => m.CashflowComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'your-objectives',
        title: "Your objectives",
        loadComponent: () => import("./steps/your-objectives/your-objectives.component").then((m) => m.YourObjectivesComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'set-your-objectives',
        title: "Set your objectives",
        loadComponent: () => import("./steps/your-objectives/set-your-objectives/set-your-objectives.component").then((m) => m.SetYourObjectivesComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'order-objective-priority',
        title: "Let's order by priority",
        loadComponent: () => import("./steps/your-objectives/order-objective-priority/order-objective-priority.component").then((m) => m.OrderObjectivePriorityComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'connect-your-objectives',
        title: "Connecting your objectives with advice",
        loadComponent: () => import("./steps/your-objectives/connect-your-objectives/connect-your-objectives.component").then((m) => m.ConnectYourObjectivesComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'advice-fee-overview',
        title: "Advice Fee Overview",
        loadComponent: () => import("./steps/agreement/advice-fee-overview/advice-fee-overview.component").then((m) => m.AdviceFeeOverviewComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'expect-next',
        title: "What to Expect Next",
        loadComponent: () => import("./steps/agreement/expect-next/expect-next.component").then((m) => m.ExpectNextComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'your-health',
        title: "Your Health",
        loadComponent: () => import("./steps/agreement/your-health/your-health.component").then((m) => m.YourHealthComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [authGuard]
    },
    {
        path: 'publish-confirmation',
        title: "Publish confirmation",
        loadComponent: () => import("./publish-confirmation/publish-confirmation.component").then((m) => m.PublishConfirmationComponent)
    },
    {
        path: 'error-page',
        title: "Error page",
        loadComponent: () => import("./error-page/error-page.component").then((m) => m.ErrorPageComponent)
    }
];
