import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { HouseholdService } from '../services/household.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = async (route) => {  
  const authService = inject(AuthService);
  const router = inject(Router);
  const householdService = inject(HouseholdService);

  if (!await authService.isAuthenticatedAsync()) {
    const clientID = route.queryParamMap.get('clientID');
    
    if (!clientID) {
      return router.navigateByUrl("/error-page");
    }

    window.location.assign(`login?clientID=${clientID}`);
    return false;
  }

  if (route.routeConfig?.path === "") {
    return route.queryParamMap.get('clientID') ? true : router.navigateByUrl("/error-page");
  }

  return householdService.household ? true : router.navigateByUrl("/error-page");
};
