@if (step.lastStep) {
    <div class="stepPrefix">
        <div class="leftLine">
            @if (leftLineCompleted()) {
                <img src="assets/line-icon2.svg" alt="" class="icon" />
            } @else {
                <img src="assets/line-icon.svg" alt="" class="icon" />
            }            
        </div>
        <div class="markerContainer">
            <button mat-fab  [class]="buttonClass()" (click)="onNavigateClick()" [disabled]="disableNavigation()">
                @if (buttonClass() === "completed") {
                    <img src="assets/tick-icon.svg" alt="" class="tickIcon" />    
                } @else {
                    {{step.parentOrder}}
                }              
            </button> 
        </div>  
    </div>
    <div class="lastStepTitle">
        <div class="title end">{{step.title}}</div>
    </div>

} @else if (step.parentOrder === 1) {
    <div class="stepPrefix">
        <div class="markerContainer">
            <button mat-fab  [class]="buttonClass()" (click)="onNavigateClick()" [disabled]="disableNavigation()">
                @if (buttonClass() === "completed") {
                    <img src="assets/tick-icon.svg" alt="" class="tickIcon" />    
                } @else {
                    {{step.parentOrder}}
                }
            </button> 
        </div>
        <div class="rightLine">
            @if (rightLineCompleted()) {
                <img src="assets/line-icon2.svg" alt="" class="icon" />
            } @else {
                <img src="assets/line-icon.svg" alt="" class="icon" />
            } 
        </div> 
      </div>
      <div class="stepTitle">
        <div class="title">{{step.title}}</div>
    </div>
} @else {
    <div class="stepPrefix">
        <div class="leftLine">
            @if (leftLineCompleted()) {
                <img src="assets/line-icon2.svg" alt="" class="icon" />
            } @else {
                <img src="assets/line-icon.svg" alt="" class="icon" />
            } 
        </div>
        <div class="markerContainer">
            <button mat-fab  [class]="buttonClass()" (click)="onNavigateClick()" [disabled]="disableNavigation()">
                @if (buttonClass() === "completed") {
                    <img src="assets/tick-icon.svg" alt="" class="tickIcon" />    
                } @else {
                    {{step.parentOrder}}
                }
            </button> 
        </div>
        <div class="rightLine">
            @if (rightLineCompleted()) {
                <img src="assets/line-icon2.svg" alt="" class="icon" />
            } @else {
                <img src="assets/line-icon.svg" alt="" class="icon" />
            } 
        </div>
      </div>
      <div class="middleStepTitle">
        <div class="title centered">{{step.title}}</div>
      </div>
}
