import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomRequestInterceptorService implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, class-methods-use-this
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let localRequest = request

    if (!localRequest.headers.has('Content-Type')) {
      localRequest = localRequest.clone({ setHeaders: { 'Content-Type': 'application/json' } });
    }

    if (!localRequest.headers.has('Accept')) {
      localRequest = localRequest.clone({ setHeaders: { Accept: 'application/json' } });
    }

    return next.handle(localRequest).pipe(catchError((err: { status: number }) => {

      return throwError(() => err);
    }))
  }
}
