import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { AuthService } from './services/auth.service';
import { ErrorPageComponent } from './error-page/error-page.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { PrivacyComponent } from './steps/welcome/privacy/privacy.component';
import { PublishConfirmationComponent } from './publish-confirmation/publish-confirmation.component';
import { RouterOutlet } from '@angular/router';
import { SaveConfirmationComponent } from './save-confirmation/save-confirmation.component';
import { TextTemplateService } from './services/text-template.service';
import { WelcomeComponent } from './steps/welcome/welcome.component';
import { YourHealthComponent } from './steps/agreement/your-health/your-health.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, FooterComponent, NavigationComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  constructor(private textTemplateService: TextTemplateService, 
    private authService: AuthService
  ) {

  }

  title = 'Engagement Agreement';
  showNavigation = signal(true);
  showSaveAndExitButton = signal(true);
  showLogoutButton = signal(false);

  ngOnInit(): void {
    const loadTextTemplate = async () => {
      if (await this.authService.isAuthenticatedAsync()) {
        await this.textTemplateService.loadTexttemplate();
      }
      
    };

    void loadTextTemplate();
  }

  handleChildEvent(component: object) {
    if (component instanceof SaveConfirmationComponent) {
      (component).showHeaderAndNavigation.subscribe((result: boolean) => {
        this.showNavigation.set(result);
        this.showSaveAndExitButton.set(result);
      });

      (component).showLogoutButton.subscribe((result: boolean) => {
        this.showLogoutButton.set(result);
      });
    }    

    if (component instanceof WelcomeComponent) {
      (component).showSaveAndExitButton.subscribe((result: boolean) => {
        this.showSaveAndExitButton.set(result);
      });
    }  

    if (component instanceof PrivacyComponent) {
      (component).showSaveAndExitButton.subscribe((result: boolean) => {
        this.showSaveAndExitButton.set(result);
      });
    } 

    if (component instanceof YourHealthComponent) {
      (component).showHeaderAndNavigation.subscribe((result: boolean) => {
        this.showNavigation.set(result);
        this.showSaveAndExitButton.set(result);
      });
    }  

    if (component instanceof PublishConfirmationComponent) {
      (component).showHeaderAndNavigation.subscribe((result: boolean) => {
        this.showNavigation.set(result);
        this.showSaveAndExitButton.set(result);
      });

      (component).showLogoutButton.subscribe((result: boolean) => {
        this.showLogoutButton.set(result);
      });
    }  

    if (component instanceof ErrorPageComponent) {
      (component).showHeaderAndNavigation.subscribe((result: boolean) => {
        this.showNavigation.set(result);
        this.showSaveAndExitButton.set(result);
      });
    }  
  }
}
